import { Box, Container } from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import { useEffect, useState } from "react";

function Setting() {
	const [setting, setSetting] = useState();
	const [load, setLoad] = useState(true);
	const [dedacbiet, setDedacbiet] = useState([]);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		if (load == true) {
			axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
				setSetting(res.data.data[0]);
				setLoad(false);
				const ddb = res.data.data[0].dedacbiet.split(" ");
				setDedacbiet([...dedacbiet, ...ddb]);
			});
		}
	}, [load]);
	const handleSubmit = (e) => {
		e.preventDefault();
		const newData = [];
		dedacbiet.map((item) => {
			newData.push(item);
		});
		const formData = {
			id: setting?._id,
			doiben: Number(e.target.doiben.value),

			xucsac3p: Number(e.target.xucsac3p.value),
			haitrung3: Number(e.target.haitrung3.value),
			haitrung3bk: Number(e.target.haitrung3bk.value),
			batrung3: Number(e.target.batrung3.value),
			batrung3bk: Number(e.target.batrung3bk.value),

			v1xucsac3p: Number(e.target.v1xucsac3p.value),
			v1haitrung3: Number(e.target.v1haitrung3.value),
			v1haitrung3bk: Number(e.target.v1haitrung3bk.value),
			v1batrung3: Number(e.target.v1batrung3.value),
			v1batrung3bk: Number(e.target.v1batrung3bk.value),

			v2xucsac3p: Number(e.target.v2xucsac3p.value),
			v2haitrung3: Number(e.target.v2haitrung3.value),
			v2haitrung3bk: Number(e.target.v2haitrung3bk.value),
			v2batrung3: Number(e.target.v2batrung3.value),
			v2batrung3bk: Number(e.target.v2batrung3bk.value),

			v3xucsac3p: Number(e.target.v3xucsac3p.value),
			v3haitrung3: Number(e.target.v3haitrung3.value),
			v3haitrung3bk: Number(e.target.v3haitrung3bk.value),
			v3batrung3: Number(e.target.v3batrung3.value),
			v3batrung3bk: Number(e.target.v3batrung3bk.value),

			lothuong: Number(e.target.lothuong.value),
			bacang: Number(e.target.bacang.value),
			de: Number(e.target.de.value),
			loxien2: Number(e.target.loxien2.value),
			loxien3: Number(e.target.loxien3.value),
			loxien4: Number(e.target.loxien4.value),

			mblothuong: Number(e.target.mblothuong.value),
			mbbacang: Number(e.target.mbbacang.value),
			mbde: Number(e.target.mbde.value),
			mbloxien2: Number(e.target.mbloxien2.value),
			mbloxien3: Number(e.target.mbloxien3.value),
			mbloxien4: Number(e.target.mbloxien4.value),

			mtlothuong: Number(e.target.mtlothuong.value),
			mtbacang: Number(e.target.mtbacang.value),
			mtde: Number(e.target.mtde.value),
			mtloxien2: Number(e.target.mtloxien2.value),
			mtloxien3: Number(e.target.mtloxien3.value),
			mtloxien4: Number(e.target.mtloxien4.value),

			mnlothuong: Number(e.target.mnlothuong.value),
			mnbacang: Number(e.target.mnbacang.value),
			mnde: Number(e.target.mnde.value),
			mnloxien2: Number(e.target.mnloxien2.value),
			mnloxien3: Number(e.target.mnloxien3.value),
			mnloxien4: Number(e.target.mnloxien4.value),

			aff: Number(e.target.aff.value),
			min: Number(e.target.min.value),
			max: Number(e.target.max.value),
			minnap: Number(e.target.minnap.value),
			maxnap: Number(e.target.maxnap.value),
			dedacbiet: newData.join(" "),
			_dedacbiet: Number(e.target._dedacbiet.value),
		};

		axios
			.put(`${process.env.REACT_APP_API_URL}/setting/update`, formData)
			.then((res) => {
				setLoad(true);
				swal("Sửa thông tin trò chơi thành công!").then(() =>
					window.location.reload()
				);
			})
			.catch((res) => setLoad(true));
	};

	const onChoose = (e) => {
		if (dedacbiet.includes(e.target.id)) {
			setDedacbiet(dedacbiet.filter((item) => item !== e.target.id));
		} else {
			setDedacbiet([...dedacbiet, e.target.id]);
		}
	};
	const numbers = Array.from(Array(100).keys());
	return (
		<>
			<ThemeProvider theme={theme}>
				<DashboardLayout>
					{
						<Box
							component="main"
							sx={{
								flexGrow: 1,
								py: 8,
							}}
						>
							<Container maxWidth={false}>
								<div style={{ fontSize: "25px", fontWeight: 700 }}>Cài đặt trả thưởng</div>
								<div style={{ marginTop: "20px" }}>
									<form className="setting" onSubmit={handleSubmit}>
										<h3
											style={{
												width: "100%",
												flex: "0 0 100%",
												textAlign: "left",
												fontWeight: "bold",
												margin: "30px 10px 10px",
											}}
										>
											Cài đặt chung
										</h3>
										<div className="form_col">
											<div className="form_group">
												<label>Tiền cược tối thiểu</label>
												<input
													defaultValue={setting?.min}
													type="number"
													name="min"
													step="any"
													id="min"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Tiền cược tối đa</label>
												<input
													defaultValue={setting?.max}
													type="number"
													name="max"
													step="any"
													id="max"
													className="input_setting"
												/>
											</div>
										</div>
										<div style={{ clear: "both", width: "100%" }}></div>
										<div className="form_col">
											<div className="form_group">
												<label>Nạp tối thiểu</label>
												<input
													defaultValue={setting?.minnap}
													type="number"
													name="minnap"
													step="any"
													id="minnap"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Nạp tối đa</label>
												<input
													defaultValue={setting?.maxnap}
													type="number"
													name="maxnap"
													step="any"
													id="maxnap"
													className="input_setting"
												/>
											</div>
										</div>

										{/* vip 1 */}
										<h3
											style={{
												width: "100%",
												flex: "0 0 100%",
												textAlign: "left",
												fontWeight: "bold",
												margin: "30px 10px 10px",
											}}
										>
											Xúc xắc VIP-1 (Dưới 100,000 USDT)
										</h3>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc  xắc CLTX</label>
												<input
													defaultValue={setting?.v1xucsac3p}
													type="number"
													name="v1xucsac3p"
													step="any"
													id="v1xucsac3p"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 2 số trùng</label>
												<input
													defaultValue={setting?.v1haitrung3}
													type="number"
													name="v1haitrung3"
													step="any"
													id="v1haitrung3"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 2 số trùng bất kỳ</label>
												<input
													defaultValue={setting?.v1haitrung3bk}
													type="number"
													name="v1haitrung3bk"
													step="any"
													id="v1haitrung3bk"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 3 số trùng</label>
												<input
													defaultValue={setting?.v1batrung3}
													type="number"
													name="v1batrung3"
													step="any"
													id="v1batrung3"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 3 số trùng bất kỳ</label>
												<input
													defaultValue={setting?.v1batrung3bk}
													type="number"
													name="v1batrung3bk"
													step="any"
													id="v1batrung3bk"
													className="input_setting"
												/>
											</div>
										</div>

										{/* vip2 */}
										<h3
											style={{
												width: "100%",
												flex: "0 0 100%",
												textAlign: "left",
												fontWeight: "bold",
												margin: "30px 10px 10px",
											}}
										>
											Xúc xắc VIP-2 (1 - 999,999 USDT)
										</h3>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc  xắc CLTX</label>
												<input
													defaultValue={setting?.v2xucsac3p}
													type="number"
													name="v2xucsac3p"
													step="any"
													id="v2xucsac3p"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 2 số trùng</label>
												<input
													defaultValue={setting?.v2haitrung3}
													type="number"
													name="v2haitrung3"
													step="any"
													id="v2haitrung3"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 2 số trùng bất kỳ</label>
												<input
													defaultValue={setting?.v2haitrung3bk}
													type="number"
													name="v2haitrung3bk"
													step="any"
													id="v2haitrung3bk"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 3 số trùng</label>
												<input
													defaultValue={setting?.v2batrung3}
													type="number"
													name="v2batrung3"
													step="any"
													id="v2batrung3"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 3 số trùng bất kỳ</label>
												<input
													defaultValue={setting?.v2batrung3bk}
													type="number"
													name="v2batrung3bk"
													step="any"
													id="v2batrung3bk"
													className="input_setting"
												/>
											</div>
										</div>

										{/* vip3 */}
										<h3
											style={{
												width: "100%",
												flex: "0 0 100%",
												textAlign: "left",
												fontWeight: "bold",
												margin: "30px 10px 10px",
											}}
										>
											Xúc xắc VIP-3 (Trên 1,000,000 USDT)
										</h3>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc  xắc CLTX</label>
												<input
													defaultValue={setting?.v3xucsac3p}
													type="number"
													name="v3xucsac3p"
													step="any"
													id="v3xucsac3p"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 2 số trùng</label>
												<input
													defaultValue={setting?.v3haitrung3}
													type="number"
													name="v3haitrung3"
													step="any"
													id="v3haitrung3"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 2 số trùng bất kỳ</label>
												<input
													defaultValue={setting?.v3haitrung3bk}
													type="number"
													name="v3haitrung3bk"
													step="any"
													id="v3haitrung3bk"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 3 số trùng</label>
												<input
													defaultValue={setting?.v3batrung3}
													type="number"
													name="v3batrung3"
													step="any"
													id="v3batrung3"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 3 số trùng bất kỳ</label>
												<input
													defaultValue={setting?.v3batrung3bk}
													type="number"
													name="v3batrung3bk"
													step="any"
													id="v3batrung3bk"
													className="input_setting"
												/>
											</div>
										</div>

										{/* xúc xắc thường */}
										<h3
											style={{
												width: "100%",
												flex: "0 0 100%",
												textAlign: "left",
												fontWeight: "bold",
												margin: "30px 10px 10px",
											}}
										>
											Xúc xắc thường
										</h3>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc  xắc CLTX</label>
												<input
													defaultValue={setting?.xucsac3p}
													type="number"
													name="xucsac3p"
													step="any"
													id="xucsac3p"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 2 số trùng</label>
												<input
													defaultValue={setting?.haitrung3}
													type="number"
													name="haitrung3"
													step="any"
													id="haitrung3"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 2 số trùng bất kỳ</label>
												<input
													defaultValue={setting?.haitrung3bk}
													type="number"
													name="haitrung3bk"
													step="any"
													id="haitrung3bk"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 3 số trùng</label>
												<input
													defaultValue={setting?.batrung3}
													type="number"
													name="batrung3"
													step="any"
													id="batrung3"
													className="input_setting"
												/>
											</div>
										</div>
										<div className="form_col">
											<div className="form_group">
												<label>Xúc xắc 3 số trùng bất kỳ</label>
												<input
													defaultValue={setting?.batrung3bk}
													type="number"
													name="batrung3bk"
													step="any"
													id="batrung3bk"
													className="input_setting"
												/>
											</div>
										</div>

										<h3
											style={{
												width: "100%",
												flex: "0 0 100%",
												textAlign: "left",
												fontWeight: "bold",
												margin: "30px 10px 10px",
											}}
										>
											Keno
										</h3>
										<div className="form_col">
											<div className="form_group">
												<label>Đôi bên</label>
												<input
													defaultValue={setting?.doiben}
													type="number"
													name="doiben"
													step="any"
													id="doiben"
													className="input_setting"
												/>
											</div>
										</div>


										<div style={{ display: 'none' }}>
											<h3
												style={{
													width: "100%",
													flex: "0 0 100%",
													textAlign: "left",
													fontWeight: "bold",
													margin: "30px 10px 10px",
												}}
											>
												Xổ số nhanh
											</h3>
											<div className="form_col">
												<div className="form_group">
													<label>Lô thường</label>
													<input
														defaultValue={setting?.lothuong}
														type="number"
														name="lothuong"
														step="any"
														id="lothuong"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Ba càng</label>
													<input
														defaultValue={setting?.bacang}
														type="number"
														name="bacang"
														step="any"
														id="bacang"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Đề</label>
													<input
														defaultValue={setting?.de}
														type="number"
														step="any"
														name="de"
														id="de"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 2</label>
													<input
														defaultValue={setting?.loxien2}
														type="number"
														name="loxien2"
														step="any"
														id="loxien2"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 3</label>
													<input
														defaultValue={setting?.loxien3}
														type="number"
														name="loxien3"
														step="any"
														id="loxien3"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 4</label>
													<input
														defaultValue={setting?.loxien4}
														type="number"
														name="loxien4"
														step="any"
														id="loxien4"
														className="input_setting"
													/>
												</div>
											</div>
											<h3
												style={{
													width: "100%",
													flex: "0 0 100%",
													textAlign: "left",
													fontWeight: "bold",
													margin: "30px 10px 10px",
												}}
											>
												XSMB
											</h3>
											<div className="form_col">
												<div className="form_group">
													<label>Lô thường</label>
													<input
														defaultValue={setting?.mblothuong}
														type="number"
														name="mblothuong"
														step="any"
														id="mblothuong"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Ba càng</label>
													<input
														defaultValue={setting?.mbbacang}
														type="number"
														name="mbbacang"
														step="any"
														id="mbbacang"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Đề</label>
													<input
														defaultValue={setting?.mbde}
														type="number"
														step="any"
														name="mbde"
														id="mbde"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 2</label>
													<input
														defaultValue={setting?.mbloxien2}
														type="number"
														name="mbloxien2"
														step="any"
														id="mbloxien2"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 3</label>
													<input
														defaultValue={setting?.mbloxien3}
														type="number"
														name="mbloxien3"
														step="any"
														id="mbloxien3"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 4</label>
													<input
														defaultValue={setting?.mbloxien4}
														type="number"
														name="mbloxien4"
														step="any"
														id="mbloxien4"
														className="input_setting"
													/>
												</div>
											</div>
											<h3
												style={{
													width: "100%",
													flex: "0 0 100%",
													textAlign: "left",
													fontWeight: "bold",
													margin: "30px 10px 10px",
												}}
											>
												XSMT
											</h3>
											<div className="form_col">
												<div className="form_group">
													<label>Lô thường</label>
													<input
														defaultValue={setting?.mtlothuong}
														type="number"
														name="mtlothuong"
														step="any"
														id="mtlothuong"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Ba càng</label>
													<input
														defaultValue={setting?.mtbacang}
														type="number"
														name="mtbacang"
														step="any"
														id="mtbacang"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Đề</label>
													<input
														defaultValue={setting?.mtde}
														type="number"
														step="any"
														name="mtde"
														id="mtde"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 2</label>
													<input
														defaultValue={setting?.mtloxien2}
														type="number"
														name="mtloxien2"
														step="any"
														id="mtloxien2"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 3</label>
													<input
														defaultValue={setting?.mtloxien3}
														type="number"
														name="mtloxien3"
														step="any"
														id="mtloxien3"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 4</label>
													<input
														defaultValue={setting?.mtloxien4}
														type="number"
														name="mtloxien4"
														step="any"
														id="mtloxien4"
														className="input_setting"
													/>
												</div>
											</div>
											<h3
												style={{
													width: "100%",
													flex: "0 0 100%",
													textAlign: "left",
													fontWeight: "bold",
													margin: "30px 10px 10px",
												}}
											>
												XSMN
											</h3>
											<div className="form_col">
												<div className="form_group">
													<label>Lô thường</label>
													<input
														defaultValue={setting?.mnlothuong}
														type="number"
														name="mnlothuong"
														step="any"
														id="mnlothuong"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Ba càng</label>
													<input
														defaultValue={setting?.mnbacang}
														type="number"
														name="mnbacang"
														step="any"
														id="mnbacang"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Đề</label>
													<input
														defaultValue={setting?.mnde}
														type="number"
														step="any"
														name="mnde"
														id="mnde"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 2</label>
													<input
														defaultValue={setting?.mnloxien2}
														type="number"
														name="mnloxien2"
														step="any"
														id="mnloxien2"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 3</label>
													<input
														defaultValue={setting?.mnloxien3}
														type="number"
														name="mnloxien3"
														step="any"
														id="mnloxien3"
														className="input_setting"
													/>
												</div>
											</div>
											<div className="form_col">
												<div className="form_group">
													<label>Lô xiên 4</label>
													<input
														defaultValue={setting?.mnloxien4}
														type="number"
														name="mnloxien4"
														step="any"
														id="mnloxien4"
														className="input_setting"
													/>
												</div>
											</div>
											<h3
												style={{
													width: "100%",
													flex: "0 0 100%",
													textAlign: "left",
													fontWeight: "bold",
													margin: "30px 10px 10px",
												}}
											>
												Khác
											</h3>
											<div className="form_col">
												<div className="form_group">
													<label>Hoa hồng</label>
													<input
														defaultValue={setting?.aff}
														type="number"
														name="aff"
														step="any"
														id="aff"
														className="input_setting"
													/>
												</div>
											</div>
											<div style={{ clear: "both", width: "100%" }}></div>
											<div
												className="text_choose_center"
												style={{ fontSize: "18px", maxWidth: "600px" }}
											>
												<h3
													style={{
														width: "100%",
														flex: "0 0 100%",
														textAlign: "left",
														fontWeight: "bold",
														margin: "30px 10px 10px",
													}}
												>
													Đề đặc biệt
												</h3>
												<div className="state_choose notranslate">
													{numbers.map((number) => (
														<div
															key={number}
															id={number < 10 ? `0${number}` : number}
															onClick={onChoose}
															className={`choose_xs ${dedacbiet.includes(
																String(number < 10 ? `0${number}` : number)
															)
																? "chooseItem"
																: ""
																}`}
														>
															{number < 10 ? `0${number}` : number}
														</div>
													))}
												</div>
												<div
													className="form_group"
													style={{ padding: "10px 20px" }}
												>
													<label>Tỉ lệ thưởng</label>
													<input
														defaultValue={setting?._dedacbiet}
														type="number"
														name="_dedacbiet"
														step="any"
														id="_dedacbiet"
														className="input_setting"
													/>
												</div>
											</div>
										</div>
										<div style={{ clear: "both", width: "100%" }}></div>
										<div
											className="form_col"
											style={{
												clear: "both",
												width: "100%",
												margin: "30px 0 0",
											}}
										>
											<button type="submit" className="btn_setting">
												Lưu
											</button>
										</div>
									</form>
								</div>
							</Container>
						</Box>
					}
				</DashboardLayout>
			</ThemeProvider>
		</>
	);
}

export default Setting;
