import Footer from "../../components/Footer/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Header from "../components/Header";
import { useTranslation } from 'react-i18next';

function WithDraw() {
	const { t } = useTranslation();
	const [profile, setProfile] = useState(null);
	const [bank, setBank] = useState(null);
	const [isShow, setShow] = useState(false);
	const [disabled, setDisabled] = useState(false);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
		axios
			.get(`${process.env.REACT_APP_API_URL}/bank/getBank`, {})
			.then((res) => {
				setBank(res.data.data);
			})
			.catch((err) => setBank(null));
	}, []);

	const onSubmit = (data) => {
		setDisabled(true);
		if (profile.isFreeze) {
			swal({
				// title: "Thông báo",
				text: t("alert_freeze"),
				icon: "error",
				buttons: "OK",
			});
			setDisabled(false);
			return;
		}

		if (Number(data.money) > Number(profile.money)) {
			setError("money", {
				type: "minLength",
				message: t("Số tiền rút vui lòng nhỏ hơn số dư hiện tại"),
			});
			setDisabled(false);
			return;
		}
		// if (data.detail) {
		// 	const formData = {
		// 		money: data.money,
		// 		type_payment: "RÚT",
		// 		detail: data.detail,
		// 		status_payment: "Pending",
		// 		user: profile._id,
		// 	};
		// 	axios
		// 		.post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
		// 		.then((res) => {
		// 			swal({
		// 				title: "Thông báo",
		// 				text: "Tạo yêu cầu rút tiền thành công!",
		// 				icon: "success",
		// 				buttons: "OK",
		// 			}).then(() => navigate("/historyget"));
		// 		})
		// 		.catch((err) =>
		// 			setError("money", {
		// 				type: "minLength",
		// 				message: "Lỗi giao dịch 404!",
		// 			})
		// 		);
		// } else {
		const formData = {
			money: Number(data.money),
			type_payment: "RÚT",
			detail: data.bank + " - " + data.stk + " - " + data.name,
			status_payment: "Pending",
			user: profile._id,
		};

		axios
			.post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
			.then((res) => {
				setDisabled(false);
				swal({
					title: t("Thông báo"),
					text: t("Tạo yêu cầu rút tiền thành công"),
					icon: "success",
					buttons: "OK",
				}).then(() => navigate("/historyget"));
			})
			.catch((err) => {
				setDisabled(false);
				setError("money", {
					type: "minLength",
					message: "Error",
				})
				swal({
					title: t("Thông báo"),
					text: t("Thất bại"),
					icon: "error",
					buttons: "OK",
				})
			});

		// }
	};
	return (
		<>
			<div className="main">
				<Header profile={profile} />
				<h1 className="title-h1">{t("Rút tiền")}</h1>
				<div className="content_profile" style={{ margin: "1.5rem 0 0" }}>
					<div style={{ display: "block", margin: "1.8rem 0 0" }}>
						{/* <Link
						to="/addbank"
						style={{ display: "block", margin: "1.8rem 0 0" }}
					> */}
						<div className="box-banking box-banking2">
							<div className="money_banking">
								<h3>{t("Số dư khả dụng")}</h3>
								{profile ? (
									<>
										<h4>{profile.money} USDT</h4>
									</>
								) : (
									<>
										<h4>0 USDT</h4>
									</>
								)}
							</div>
							<div className="ctk">{profile ? profile.username : null}</div>
							<div className="icon_credit">
								<img src={require("../../img/icon_credit.png")} />
							</div>
						</div>
					</div>
					<form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
						<div>
							{/* {bank == null ? (
								<>
									<div>
										<p>Vui lòng thêm ngân hàng</p>
									</div>
									<Link to="/addbank" className="btn-medium">
										Thêm Ngân Hàng
									</Link>
								</>
							) : ( */}

							<div>
								<input
									className="ipadd"
									type="number"
									step="0.01"
									style={{ fontSize: '0.3433rem' }}
									{...register("money", { required: true })}
									placeholder={t("Nhập số tiền cần rút")}
								/>
							</div>

							<div style={{ margin: '15px 0px 10px' }}>{t("Thông tin tài khoản nhận tiền")}</div>
							<div>
								<input
									className="ipadd"
									type="text"
									style={{ fontSize: '0.3433rem' }}
									{...register("bank", { required: true })}
									placeholder={t("Tên ngân hàng")}
								/>
							</div>
							<div>
								<input
									className="ipadd"
									type="text"
									style={{ fontSize: '0.3433rem' }}
									{...register("stk", { required: true })}
									placeholder={t("Số tài khoản")}
								/>
							</div>
							<div>
								<input
									className="ipadd"
									type="text"
									style={{ fontSize: '0.3433rem' }}
									{...register("name", { required: true })}
									placeholder={t("Chủ tài khoản")}
								/>
							</div>
							{/* <select
										style={{ color: "#333" }}
										{...register("detail", { required: true })}
									>
										<option value="">Chọn ngân hàng</option>
										{bank.map((item, index) => (
											<option
												key={index}
												value={
													item.name_bank +
													" - " +
													item.fullname +
													" - " +
													item?.stk
												}
											>
												{"STK " +
													item?.stk +
													" - " +
													item.fullname +
													" - " +
													item.name_bank}
											</option>
										))}
									</select> */}
							<button type="submit" className="btn-submit" disabled={disabled}>
								{t("Xác nhận")}
							</button>

							{errors.money ? (
								<p style={{ color: "#ffc200" }}>{errors.money.message}</p>
							) : null}
						</div>
					</form>
				</div>

				<Footer />
			</div>
		</>
	);
}
export default WithDraw;
