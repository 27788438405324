// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .use(LanguageDetector) // Detects user language
  .use(HttpApi) // Loads translations using http (default public/assets/locales)
  .init({
    supportedLngs: ['vi', 'en', 'cn'],
    fallbackLng: 'vi',
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'sessionStorage', 'navigator'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
