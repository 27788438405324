import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import { toast, ToastContainer } from "react-toastify";
import {
	Box,
	Button,
	Container,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Pagination,
	TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

const convertMoney = (money) => {
	let m = Number(money);
	let d = Math.round(2*m/100);
	return m-d;
}

function Request() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [data, setData] = useState(null);
	const [load, setLoad] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [itemSelected, setItemSelected] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const [searched, setSearched] = useState("");
	const [pages, setPage] = useState(1);
	const requestSearch = (searchedVal) => {
		setSearched(searchedVal);

		if (searchedVal !== "") {
			const filteredRows = JSON.parse(localStorage.getItem("data")).filter(
				(row) => {
					return row.user.iduser.toString().includes(searchedVal.toLowerCase());
				}
			);
			setData(filteredRows);
		} else {
			setData(JSON.parse(localStorage.getItem("data")));
		}
	};
	const handleChange = (e, page) => {
		setPage(page);
	};
	const handleSelect = (e) => {
		setShowModal(true);
		setItemSelected(e);
	}
	const handleSubmitEdit = () => {
		let val = document.getElementById('admin_widthraw_modal_edit').value.trim();
		const formData = {
			id: itemSelected._id,
			detail: val,
		};
		axios.post(
			`${process.env.REACT_APP_API_URL}/payment/updateDetail`,
			formData
		).then((res) => { setShowModal(false); setLoad(true); })
			.catch((err) => { setShowModal(false); setLoad(true); });
	}
	useEffect(() => {
		if (load === true) {
			axios.get(`${process.env.REACT_APP_API_URL}/payment/rut`, {}).then((res) => {
				setData(res.data.data);
				localStorage.setItem("data", JSON.stringify(res.data.data));
				setLoad(false);
			});
		}
	}, [load]);
	if (data !== null) {
		return (
			<>
				<ThemeProvider theme={theme}>
					<DashboardLayout>
						{
							<Box
								component="main"
								sx={{
									flexGrow: 1,
									py: 1,
								}}
							>
								{data && (
									<Container maxWidth={false}>
										<div className="container_set">Yêu cầu rút tiền</div>
										<div className="form_set">
											<Box sx={{ minWidth: 600 }}>
												<TextField
													value={searched}
													onChange={(searchVal) =>
														requestSearch(searchVal.target.value)
													}
													placeholder="Tìm kiếm"
													sx={{ marginBottom: "5px", paddingRight: "700px" }}
												/>
												<Table sx={{ width: 1200 }}>
													<TableHead>
														<TableRow>
															<TableCell>Tên tài khoản</TableCell>
															<TableCell>ID USER</TableCell>
															<TableCell>Thông tin thanh toán</TableCell>
															<TableCell>Số tiền</TableCell>
															<TableCell>Trạng thái</TableCell>
															<TableCell>Hành Động</TableCell>
															<TableCell>Thời gian</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{data
															?.slice((pages - 1) * 10, (pages - 1) * 10 + 10)
															.map((item) => (
																<>
																	<TableRow>
																		<TableCell className="notranslate" sx={{ fontWeight: "600" }}>
																			{item?.user?.username}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item?.user?.iduser}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item.detail}
																		</TableCell>

																		<TableCell sx={{ fontWeight: "600" }}>
																			{item.money.toLocaleString()} USDT
																		</TableCell>
																		{item?.status_payment === "Pending" ? (
																			<TableCell>
																				<Button type="text" color="warning">
																					{item?.status_payment == "Success"
																						? "Thành công"
																						: item?.status_payment == "Deny"
																							? "Thất bại"
																							: "Đang xử lý"}
																				</Button>
																			</TableCell>
																		) : null}
																		{item?.status_payment === "Success" ? (
																			<TableCell>
																				<Button type="text" color="success">
																					{item?.status_payment == "Success"
																						? "Thành công"
																						: item?.status_payment == "Deny"
																							? "Thất bại"
																							: "Đang xử lý"}
																				</Button>
																			</TableCell>
																		) : null}
																		{item?.status_payment === "Deny" ? (
																			<TableCell>
																				<Button type="text" color="error">
																					{item?.status_payment == "Success"
																						? "Thành công"
																						: item?.status_payment == "Deny"
																							? "Thất bại"
																							: "Đang xử lý"}
																				</Button>
																			</TableCell>
																		) : null}
																		<TableCell>
																			<Button size="small"
																				onClick={() => {
																					const formData = {
																						id: item._id,
																						status: "Accept",
																					};
																					axios
																						.post(
																							`${process.env.REACT_APP_API_URL}/payment/update`,
																							formData
																						)
																						.then((res) => {
																							setLoad(true);
																						})
																						.catch((err) => setLoad(true));
																				}}
																				disabled={
																					item?.status_payment !== "Pending"
																				}
																			>
																				Xác nhận
																			</Button>
																			<Button size="small"
																				onClick={() => {
																					const formData = {
																						id: item._id,
																						status: "Deny",
																					};
																					axios
																						.post(
																							`${process.env.REACT_APP_API_URL}/payment/update`,
																							formData
																						)
																						.then((res) => {
																							setLoad(true);
																						})
																						.catch((err) => setLoad(true));
																				}}
																				disabled={
																					item?.status_payment !== "Pending"
																				}
																			>
																				Từ chối
																			</Button>
																			<Button size="small"
																				onClick={() => {
																					handleSelect(item);
																				}}
																				disabled={
																					item?.status_payment !== "Pending"
																				}
																			>
																				Sửa thông tin
																			</Button>
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{formatDate(new Date(item?.createdAt))}
																		</TableCell>
																	</TableRow>
																</>
															))}
													</TableBody>
												</Table>
											</Box>

											{(showModal && itemSelected) &&
												<div className="modal" id='modal_edit_withdraw_info'>
													<div className="modaloverlay">
														<i className="ti-close closelogin"></i>
													</div>
													<div
														style={{ backgroundColor: "white" }}
														className="modalbody"
													>
														<div className="modalinner" style={{ marginTop: '15px' }}>
															{/* <div className="modalheader">Sửa thông báo</div> */}

															<TextField
																id="admin_widthraw_modal_edit"
																label="Sửa thông tin tài khoản nhận tiền"
																multiline
																rows={3}
																sx={{ width: '100%' }}
																defaultValue={itemSelected.detail}
															/>

															<div className="item_btn_form" style={{ marginBottom: 0, }}>
																<div className="modalformcontrols">
																	<Button type="button" onClick={handleSubmitEdit} >XÁC NHẬN</Button>
																</div>
																<div className="modalformcontrols">
																	<Button onClick={() => {
																		setShowModal(false);
																	}
																	}>
																		ĐÓNG
																	</Button>
																</div>
															</div>
														</div>
													</div>
												</div>
											}

										</div>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												pt: 3,
											}}
										>
											{data != null ? (
												<Pagination
													color="primary"
													count={Math.floor(data.length / 10) + 1}
													size="small"
													onChange={handleChange}
												/>
											) : null}
										</Box>
									</Container>
								)}
							</Box>
						}

						<ToastContainer />
					</DashboardLayout>
				</ThemeProvider>
			</>
		);
	}
}
export default Request;
