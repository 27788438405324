import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import {
    Box,
    Button,
    Container,
    Input,
    Table,
    Pagination,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    IconButton,
} from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
function HistoryAddMinusMoney() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [users, setUser] = useState(null);
    const [load, setLoad] = useState(false);
    const [searched, setSearched] = useState("");
    const [table, setTable] = useState("");
    const handleChangeTable = (e) => {
        setTable(e.target.value);
    };
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");

            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }

            return config;
        },

        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (load == false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getHistoryAddMinusMoney`, {})
                .then((res) => {
                    localStorage.setItem("data", JSON.stringify(res.data.data));
                    setUser(res.data.data);
                    setLoad(true);
                })
                .then((res) => setLoad(true));
        }
    }, [load]);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const keysearch = searchedVal.toLowerCase();
            const filteredRows = JSON.parse(localStorage.getItem("data")).filter(
                (row) => {
                    return (row.username.toLowerCase().includes(keysearch) || row._iduser?.toString()?.toLowerCase()?.includes(keysearch));
                }
            );
            setUser(filteredRows);
        } else {
            setUser(JSON.parse(localStorage.getItem("data")));
        }
    };
    const [pages, setPage] = useState(1);

    const handleChange = (e, page) => {
        setPage(page);
    };
    if (users !== null) {
        return (
            <>
                <ThemeProvider theme={theme}>
                    <DashboardLayout>
                        {
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    py: 1,
                                }}
                            >
                                <Container maxWidth={false}>
                                    <div className="container_set">Lịch sử cộng/trừ tiền</div>
                                    <div className="form_set">
                                        <Box sx={{ minWidth: 600 }}>
                                            {/* <TextField
												value={searched}
												onChange={(searchVal) =>
													requestSearch(searchVal.target.value)
												}
												placeholder="Tìm kiếm"
												sx={{ marginBottom: "5px", paddingRight: "700px" }}
											/> */}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <TextField
                                                    value={searched}
                                                    onChange={(searchVal) =>
                                                        requestSearch(searchVal.target.value)
                                                    }
                                                    placeholder="Tìm kiếm"
                                                    sx={{ marginBottom: "5px", paddingRight: "700px" }}
                                                />
                                                <div>
                                                    <span>Chọn tác vụ: </span>
                                                    <select
                                                        onChange={handleChangeTable}
                                                        value={table}
                                                        id="table"
                                                    >
                                                        <option value="">Tất cả</option>
                                                        <option value="Admin Cộng">Admin Cộng</option>
                                                        <option value="Admin Trừ">Admin Trừ</option>
                                                        <option value="Admin Thưởng">Admin Thưởng</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <Table sx={{ width: 1600 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Tài khoản</TableCell>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>Tác Vụ</TableCell>
                                                        <TableCell>Số Tiền</TableCell>
                                                        <TableCell>Admin thực hiện</TableCell>
                                                        <TableCell align="center">Thời gian</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {users != null ? (
                                                    <TableBody>
                                                        {users
                                                            ?.slice((pages - 1) * 30, (pages - 1) * 30 + 30)
                                                            .map((item) => (
                                                                <>
                                                                    {table == '' ? (
                                                                        <TableRow key={item._id}>
                                                                            <TableCell className="notranslate" sx={{ fontWeight: "600" }}>
                                                                                <Button
                                                                                    onClick={() =>
                                                                                        navigate(`/admin/user/${item._iduser}`)
                                                                                    }
                                                                                >{item.username}</Button>
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: "600" }}>
                                                                                {item?._iduser}
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: "600" }}>
                                                                                {item.type}
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: "600" }}>
                                                                                {item.money.toLocaleString()}
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: "600" }}>
                                                                                {item.updatedBy}
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: "600" }}>
                                                                                {formatDate(new Date(item.updatedAt))}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ) : table !== "" && table == item.type ? (
                                                                        <TableRow key={item._id}>
                                                                            <TableCell className="notranslate" sx={{ fontWeight: "600" }}>
                                                                                <Button
                                                                                    onClick={() =>
                                                                                        navigate(`/admin/user/${item._iduser}`)
                                                                                    }
                                                                                >{item.username}</Button>
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: "600" }}>
                                                                                {item?._iduser}
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: "600" }}>
                                                                                {item.type}
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: "600" }}>
                                                                                {item.money.toLocaleString()}
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: "600" }}>
                                                                                {item.updatedBy}
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: "600" }}>
                                                                                {formatDate(new Date(item.updatedAt))}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ) : null}
                                                                </>
                                                            ))}
                                                    </TableBody>
                                                ) : (
                                                    <div>Đang cập nhật</div>
                                                )}
                                            </Table>
                                        </Box>
                                    </div>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            pt: 3,
                                        }}
                                    >
                                        {users != null ? (
                                            <Pagination
                                                color="primary"
                                                count={Math.floor(users.length / 30) + 1}
                                                size="small"
                                                onChange={handleChange}
                                            />
                                        ) : null}
                                    </Box>
                                </Container>
                            </Box>
                        }
                    </DashboardLayout>
                </ThemeProvider>
            </>
        );
    } else {
        return <></>;
    }
}
export default HistoryAddMinusMoney;
