import Footer from "../../components/Footer/Footer";
import "../user/profile.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Header from "../components/Header";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';

function Profile() {
	const { t, i18n } = useTranslation();
	const [profile, setProfile] = useState(null);
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);
	const [age, setAge] = React.useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason !== 'backdropClick') {
			setOpen(false);
		}
	};

	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
			.then((res) => {
				console.log('resss----', res)
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
	}, []);

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		setOpen(false);
	}

	return (
		<>
			<div className="main">
				<Header profile={profile} />
				<div className="account">
					<div className="account__top promotionRule__container-content__rules-item">
						<div className="promotionRule__container-content__rules-item__splitBorder">
							<span></span>
						</div>
						<div className="promotionRule__container-content__rules-item__borderTopStyle">
							<span></span>
							<span></span>
						</div>
						<div className="promotionRule__container-content__rules-item__titleLeft"></div>
						<div className="promotionRule__container-content__rules-item__title">
							{t('Thành viên')}
						</div>
						<div className="promotionRule__container-content__rules-item__titleRight"></div>
						<div className="account__ID"></div>
						<div className="account__balance">
							<span style={{ margin: "0" }}>
								<img
									src={require("../../img/profile-picture.jpg")}
									alt="Profile"
								/>
								{profile ? <span style={{ fontSize: '16px' }}>{profile.username}</span> : <span>...</span>}
								{profile ? <span style={{ fontSize: '18px', fontWeight: 700 }}>ID: {profile.iduser}</span> : ''}
							</span>
							{/*<span style={{ margin: "0" }}>
								{profile ? (
									<span>
										Mã giới thiệu <b>{profile.code}</b>
									</span>
								) : (
									<span>...</span>
								)}
								</span>*/}
							{profile ? (
								<strong id="account__balance" style={{ color: 'orange' }}>
									{profile.money} <small>USDT</small>
								</strong>
							) : (
								<strong>*****</strong>
							)}
						</div>
					</div>
					<div className="account__transaction">
						<div className="account__transaction-box">
							<Link to="/recharge" className="account__transaction-item">
								<AddCardOutlinedIcon />
								<span>{t('Nạp tiền')}</span>
							</Link>
							<div className="account__transaction-line"></div>
							<Link to="/withdraw" className="account__transaction-item">
								<PriceChangeOutlinedIcon />
								<span>{t('Rút tiền')}</span>
							</Link>
						</div>
					</div>
					<div className="account__menu">
						<Link to="/historyplay" className="account__menu-item">
							<span>
								<QueryStatsOutlinedIcon sx={{ fontSize: "25px" }} />
								{t('Lịch sử tham gia')}
							</span>
							<KeyboardArrowRightOutlinedIcon />
						</Link>
						{/*<Link to="/history" className="account__menu-item">
							<span>
								<LocalAtmOutlinedIcon sx={{ fontSize: "25px" }} />
								Biến động số dư
							</span>
							<KeyboardArrowRightOutlinedIcon />
						</Link>*/}
						<Link to="/historyadd" className="account__menu-item">
							<span>
								<CreditScoreOutlinedIcon sx={{ fontSize: "25px" }} />
								{t('Lịch sử nạp')}
							</span>
							<KeyboardArrowRightOutlinedIcon />
						</Link>
						<Link to="/historyget" className="account__menu-item">
							<span>
								<PaymentsOutlinedIcon sx={{ fontSize: "25px" }} />
								{t('Lịch sử rút')}
							</span>
							<KeyboardArrowRightOutlinedIcon />
						</Link>
						{/* <Link to="/addbank" className="account__menu-item">
							<span>
								<AccountBalanceOutlinedIcon sx={{ fontSize: "25px" }} />
								Liên kết ngân hàng
							</span>
							<KeyboardArrowRightOutlinedIcon />
						</Link> */}
						{/*<Link to="/transfer" className="account__menu-item">
							<span>
								<MoveUpIcon sx={{ fontSize: "25px" }} />
								Chuyển tiền
							</span>
							<KeyboardArrowRightOutlinedIcon />
							</Link>*/}
						<Link to="/password" className="account__menu-item">
							<span>
								<LockPersonOutlinedIcon sx={{ fontSize: "25px" }} />
								{t('Đổi mật khẩu')}
							</span>
							<KeyboardArrowRightOutlinedIcon />
						</Link>
						<div onClick={() => handleClickOpen()} className="account__menu-item">
							<span>
								{i18n.language === 'en' ?
									<svg xmlns="http://www.w3.org/2000/svg" width={'24px'}
										style={{ top: '2px', marginRight: '10px' }}
										id="flag-icons-gb" viewBox="0 0 640 480">
										<path fill="#012169" d="M0 0h640v480H0z" />
										<path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z" />
										<path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z" />
										<path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
										<path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
									</svg>
									: (i18n.language === 'vi'
										?
										<svg xmlns="http://www.w3.org/2000/svg" width={'24px'}
											style={{ top: '2px', marginRight: '10px' }}
											id="flag-icons-vn" viewBox="0 0 640 480">
											<defs>
												<clipPath id="vn-a">
													<path fillOpacity=".7" d="M-85.3 0h682.6v512H-85.3z" />
												</clipPath>
											</defs>
											<g fill-rule="evenodd" clip-path="url(#vn-a)" transform="translate(80)scale(.9375)">
												<path fill="#da251d" d="M-128 0h768v512h-768z" />
												<path fill="#ff0" d="M349.6 381 260 314.3l-89 67.3L204 272l-89-67.7 110.1-1 34.2-109.4L294 203l110.1.1-88.5 68.4 33.9 109.6z" />
											</g>
										</svg>
										:
										<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-cn" viewBox="0 0 640 480" width={'24px'}
											style={{ top: '2px', marginRight: '10px' }}
										>
											<defs>
												<path id="cn-a" fill="#ff0" d="M-.6.8 0-1 .6.8-1-.3h2z" />
											</defs>
											<path fill="#ee1c25" d="M0 0h640v480H0z" />
											<use href="#cn-a" width="30" height="20" transform="matrix(71.9991 0 0 72 120 120)" />
											<use href="#cn-a" width="30" height="20" transform="matrix(-12.33562 -20.5871 20.58684 -12.33577 240.3 48)" />
											<use href="#cn-a" width="30" height="20" transform="matrix(-3.38573 -23.75998 23.75968 -3.38578 288 95.8)" />
											<use href="#cn-a" width="30" height="20" transform="matrix(6.5991 -23.0749 23.0746 6.59919 288 168)" />
											<use href="#cn-a" width="30" height="20" transform="matrix(14.9991 -18.73557 18.73533 14.99929 240 216)" />
										</svg>
									)
								}
								{t('Đổi ngôn ngữ')}
							</span>
							<KeyboardArrowRightOutlinedIcon />
						</div>
						<div
							className="account__menu-item"
							onClick={() => {
								localStorage.removeItem("user");
								navigate("/login");
							}}
						>
							<span>
								<LogoutOutlinedIcon sx={{ fontSize: "25px" }} />
								{t('Đăng xuất')}
							</span>
							<KeyboardArrowRightOutlinedIcon />
						</div>
					</div>
				</div>
			</div>
			<Footer profile={profile} />
			<Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
				<DialogContent>
					<div style={{ fontSize: '17px', minWidth: '180px' }}>
						<div style={{ marginBottom: '16px', cursor: 'pointer', color: i18n.language === 'en' ? 'blue' : 'black' }} onClick={() => changeLanguage('en')}>
							<svg xmlns="http://www.w3.org/2000/svg" width={'24px'}
								opacity={i18n.language === 'en' ? 1 : 0.4}
								style={{ marginRight: '5px', marginBottom: '-3px' }}
								id="flag-icons-gb" viewBox="0 0 640 480">
								<path fill="#012169" d="M0 0h640v480H0z" />
								<path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z" />
								<path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z" />
								<path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
								<path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
							</svg>{t('Tiếng Anh')}
						</div>
						<div style={{ marginBottom: '16px', cursor: 'pointer', color: i18n.language === 'vi' ? 'blue' : 'black' }} onClick={() => changeLanguage('vi')}>
							<svg xmlns="http://www.w3.org/2000/svg" width={'24px'}
								opacity={i18n.language === 'vi' ? 1 : 0.4}
								style={{ marginRight: '5px', marginBottom: '-3px' }}
								id="flag-icons-vn" viewBox="0 0 640 480">
								<defs>
									<clipPath id="vn-a">
										<path fillOpacity=".7" d="M-85.3 0h682.6v512H-85.3z" />
									</clipPath>
								</defs>
								<g fill-rule="evenodd" clip-path="url(#vn-a)" transform="translate(80)scale(.9375)">
									<path fill="#da251d" d="M-128 0h768v512h-768z" />
									<path fill="#ff0" d="M349.6 381 260 314.3l-89 67.3L204 272l-89-67.7 110.1-1 34.2-109.4L294 203l110.1.1-88.5 68.4 33.9 109.6z" />
								</g>
							</svg>{t('Tiếng Việt')}
						</div>
						<div style={{ cursor: 'pointer', color: i18n.language === 'cn' ? 'blue' : 'black' }} onClick={() => changeLanguage('cn')}>
							<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-cn" viewBox="0 0 640 480" width={'24px'}
								opacity={i18n.language === 'vi' ? 1 : 0.4}
								style={{ marginRight: '5px', marginBottom: '-3px' }}
							>
								<defs>
									<path id="cn-a" fill="#ff0" d="M-.6.8 0-1 .6.8-1-.3h2z" />
								</defs>
								<path fill="#ee1c25" d="M0 0h640v480H0z" />
								<use href="#cn-a" width="30" height="20" transform="matrix(71.9991 0 0 72 120 120)" />
								<use href="#cn-a" width="30" height="20" transform="matrix(-12.33562 -20.5871 20.58684 -12.33577 240.3 48)" />
								<use href="#cn-a" width="30" height="20" transform="matrix(-3.38573 -23.75998 23.75968 -3.38578 288 95.8)" />
								<use href="#cn-a" width="30" height="20" transform="matrix(6.5991 -23.0749 23.0746 6.59919 288 168)" />
								<use href="#cn-a" width="30" height="20" transform="matrix(14.9991 -18.73557 18.73533 14.99929 240 216)" />
							</svg>{t('Tiếng Trung')}
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}
export default Profile;
