export const GetNameChoose = (state, type, sanh) => {
    if (sanh === "Xúc sắc 3p" || sanh === "Xúc sắc 5p" || sanh === "Xúc sắc 10p" || sanh === 'Xúc sắc VIP') {
        if (Number(state) == 1 && type == null) {
            return "Chọn Lớn"
        }
        if (Number(state) == 2 && type == null) {
            return "Chọn Nhỏ"
        }
        if (Number(state) == 3 && type == null) {
            return "Chọn Lẻ"
        }
        if (Number(state) == 4 && type == null) {
            return "Chọn Chẵn"
        } else {
            return "Chọn " + state
        }
    } else {
        if (Number(state) == 1 && type == null) {
            return "Đôi bên: Bi số 1 chọn Lớn"
        }
        if (Number(state) == 2 && type == null) {
            return "Đôi bên: Bi số 1 chọn Nhỏ"
        }
        if (Number(state) == 3 && type == null) {
            return "Đôi bên: Bi số 1 chọn Lẻ"
        }
        if (Number(state) == 4 && type == null) {
            return "Đôi bên: Bi số 1 chọn Chẵn"
        }
        if (Number(state) == 5 && type == null) {
            return "Đôi bên: Bi số 2 chọn Lớn"
        }
        if (Number(state) == 6 && type == null) {
            return "Đôi bên: Bi số 2 chọn Nhỏ"
        }
        if (Number(state) == 7 && type == null) {
            return "Đôi bên: Bi số 2 chọn Lẻ"
        }
        if (Number(state) == 8 && type == null) {
            return "Đôi bên: Bi số 2 chọn Chẵn"
        }
        if (Number(state) == 9 && type == null) {
            return "Đôi bên: Bi số 3 chọn Lớn"
        }
        if (Number(state) == 10 && type == null) {
            return "Đôi bên: Bi số 3 chọn Nhỏ"
        }
        if (Number(state) == 11 && type == null) {
            return "Đôi bên: Bi số 3 chọn Lẻ"
        }
        if (Number(state) == 12 && type == null) {
            return "Đôi bên: Bi số 3 chọn Chẵn"
        }
        if (Number(state) == 13 && type == null) {
            return "Đôi bên: Bi số 4 chọn Lớn"
        }
        if (Number(state) == 14 && type == null) {
            return "Đôi bên: Bi số 4 chọn Nhỏ"
        }
        if (Number(state) == 15 && type == null) {
            return "Đôi bên: Bi số 4 chọn Lẻ"
        }
        if (Number(state) == 16 && type == null) {
            return "Đôi bên: Bi số 4 chọn Chẵn"
        }
        if (Number(state) == 17 && type == null) {
            return "Đôi bên: Bi số 5 chọn Lớn"
        }
        if (Number(state) == 18 && type == null) {
            return "Đôi bên: Bi số 5 chọn Nhỏ"
        }
        if (Number(state) == 19 && type == null) {
            return "Đôi bên: Bi số 5 chọn Lẻ"
        }
        if (Number(state) == 20 && type == null) {
            return "Đôi bên: Bi số 5 chọn Chẵn"
        }
        if (Number(state) == 21 && type == null) {
            return "Đôi bên: Tổng chọn Lớn"
        }
        if (Number(state) == 22 && type == null) {
            return "Đôi bên: Tổng chọn Nhỏ"
        }
        if (Number(state) == 23 && type == null) {
            return "Đôi bên: Tổng chọn Lẻ"
        }
        if (Number(state) == 24 && type == null) {
            return "Đôi bên: Tổng chọn Chẵn"
        }
        if (type == 1 && state != null) {
            return "Lô: Chọn số " + state
        }
        if (type == 2 && state != null) {
            return "Ba càng: Chọn số " + state
        }
        if (type == 3 && state != null) {
            return "Đề: Chọn số " + state
        }
        if (type == 4 && state != null) {
            return "Lô xiên 2: Chọn số " + state
        }
        if (type == 5 && state != null) {
            return "Lô xiên 3: Chọn số " + state
        }
        if (type == 6 && state != null) {
            return "Lô xiên 4: Chọn số " + state
        }
    }
}