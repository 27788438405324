import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import {
	Box,
	Button,
	Container,
	IconButton,
	Table,
	Pagination,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
function Users() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [users, setUser] = useState(null);
	const [load, setLoad] = useState(false);
	const [searched, setSearched] = useState("");
	const navigate = useNavigate();
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);

	const [employee, setEmployee] = useState();
	useEffect(() => {
		if (load == false) {
			axios
				.get(`${process.env.REACT_APP_API_URL}/auth/getEmployee`, {})
				.then((res) => {
					localStorage.setItem("data", JSON.stringify(res.data.data));
					setUser(res.data.data);
					setLoad(true);
				})
				.then((res) => setLoad(true));
			// axios
			// 	.get(`${process.env.REACT_APP_API_URL}/auth/getEmployee`, {})
			// 	.then((res) => {
			// 		localStorage.setItem("data1", JSON.stringify(res.data.data));
			// 		setEmployee(res.data.data);
			// 		setLoad(true);
			// 	})
			// 	.then((res) => setLoad(true));
		}
	}, [load]);
	const requestSearch = (searchedVal) => {
		setSearched(searchedVal);

		if (searchedVal !== "") {
			const keysearch = searchedVal.toLowerCase();
			const filteredRows = JSON.parse(localStorage.getItem("data")).filter(
				(row) => {
					return (row.username.toLowerCase().includes(keysearch) || row.iduser?.toString()?.toLowerCase()?.includes(keysearch));
				}
			);
			setUser(filteredRows);
		} else {
			setUser(JSON.parse(localStorage.getItem("data")));
		}
	};
	const [pages, setPage] = useState(1);

	const handleChange = (e, page) => {
		setPage(page);
	};
	if (users !== null) {
		return (
			<>
				<ThemeProvider theme={theme}>
					<DashboardLayout>
						{
							<Box
								component="main"
								sx={{
									flexGrow: 1,
									py: 1,
								}}
							>
								<Container maxWidth={false}>
									<div className="container_set">Danh sách nhân viên</div>
									<div className="form_set">
										<Box sx={{ minWidth: 600 }}>
											<TextField
												value={searched}
												onChange={(searchVal) =>
													requestSearch(searchVal.target.value)
												}
												placeholder="Tìm kiếm"
												sx={{ marginBottom: "5px", paddingRight: "700px" }}
											/>
											<Table sx={{ width: 1600 }}>
												<TableHead>
													<TableRow>
														<TableCell align="center" width={72}></TableCell>
														<TableCell>Tài khoản</TableCell>
														<TableCell>ID</TableCell>
														{/* <TableCell>Người Giới Thiệu</TableCell> */}
														<TableCell>Số Dư</TableCell>
														{/*<TableCell>Ngày tạo</TableCell>*/}
														<TableCell>Admin Cộng</TableCell>

														<TableCell>Admin Trừ</TableCell>
														<TableCell>Admin Thưởng</TableCell>
														<TableCell>Tổng Đặt</TableCell>
														<TableCell>Tổng Thắng</TableCell>
														<TableCell>Cộng/Trừ Tiền</TableCell>
														<TableCell align="center">Thưởng</TableCell>
													</TableRow>
												</TableHead>
												{users != null ? (
													<TableBody>
														{users
															?.slice((pages - 1) * 30, (pages - 1) * 30 + 30)
															.map((item) => (
																<TableRow key={item._id}>
																	<TableCell
																		sx={{
																			fontWeight: "600",
																			// display: "flex",
																		}}
																	>
																		<IconButton
																			color={item.odds ? 'secondary' : 'default'}
																			onClick={() => {
																				window.open(`/admin/odds/${item.iduser}`, '_blank');
																			}}
																		>
																			<SettingsIcon />
																		</IconButton>

																		{item.isLock == false ? (
																			<IconButton
																				color='success'
																				onClick={() => {
																					axios
																						.post(
																							`${process.env.REACT_APP_API_URL}/auth/lockkey`,
																							{
																								id: item._id,

																								isLock: true,
																							}
																						)
																						.then((res) => {
																							swal("Tài khoản đã bị khóa!");
																							setLoad(false);
																						});
																				}}
																			>
																				<CheckCircleOutlineIcon />
																			</IconButton>
																		) : (
																			<IconButton
																				onClick={() => {
																					axios
																						.post(
																							`${process.env.REACT_APP_API_URL}/auth/lockkey`,
																							{
																								id: item._id,

																								isLock: false,
																							}
																						)
																						.then((res) => {
																							swal("Tài khoản đã được mở khóa!");
																							setLoad(false);
																						});
																				}}
																			>
																				<BlockIcon />
																			</IconButton>
																		)}

																		{item.isFreeze == false ? (
																			<IconButton
																				color='success'
																				onClick={() => {
																					axios
																						.post(
																							`${process.env.REACT_APP_API_URL}/auth/freezemoney`,
																							{
																								id: item._id,

																								isFreeze: true,
																							}
																						)
																						.then((res) => {
																							swal("Tài khoản đã bị đóng băng số dư. Tài khoản này sẽ không thể đặt cược hay rút tiền đến khi được mở lại!");
																							setLoad(false);
																						});
																				}}
																			>
																				<AttachMoneyIcon />
																			</IconButton>
																		) : (
																			<IconButton
																				onClick={() => {
																					axios
																						.post(
																							`${process.env.REACT_APP_API_URL}/auth/freezemoney`,
																							{
																								id: item._id,

																								isFreeze: false,
																							}
																						)
																						.then((res) => {
																							swal("Tài khoản đã được kích hoạt lại. Hiện tại có thể đặt cược và rút tiền bình thường!");
																							setLoad(false);
																						});
																				}}
																			>
																				<MoneyOffIcon />
																			</IconButton>
																		)}
																	</TableCell>
																	<TableCell className="notranslate" sx={{ fontWeight: "600" }}>
																		<Button
																			onClick={() =>
																				navigate(`/admin/user/${item.iduser}`)
																			}
																		>{item.username}</Button>
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item?.iduser}
																	</TableCell>
																	{/* <TableCell sx={{ fontWeight: "600" }}>
																			{employee &&
																				employee.find(
																					(x) => x.code === item.aff
																				)?.username}
																		</TableCell> */}
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item.money.toLocaleString()}
																	</TableCell>
																	{/*<TableCell sx={{ fontWeight: "600" }}>
																	{formatDate(new Date(item?.createdAt))}
																</TableCell>*/}
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item.adminadd.toLocaleString()}
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item.admintru.toLocaleString()}
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item.adminthuong.toLocaleString()}
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item.totalbet.toLocaleString()}
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item.totalwin.toLocaleString()}
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		<form
																			onSubmit={(e) => {
																				e.preventDefault();
																				const dataForm = {
																					id: item._id,
																					money: e.target.money.value,
																					iduser: item?.iduser,
																					username: item?.username,
																				};
																				axios
																					.post(
																						`${process.env.REACT_APP_API_URL}/auth/update`,
																						dataForm
																					)
																					.then((res) => {
																						setUser(res.data.data);
																						setLoad(false);
																						swal("Cộng/Trừ tiền thành công!");
																					});
																			}}
																		>
																			<input
																				style={{ width: "100px" }}
																				id={item._id}
																				name="money"
																				type="number"
																				step="0.01"
																			/>
																			<Button type="submit">Xác nhận</Button>
																		</form>
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		<form
																			onSubmit={(e) => {
																				e.preventDefault();
																				const dataForm = {
																					id: item._id,
																					money: e.target.money.value,
																					iduser: item?.iduser,
																					username: item?.username,
																				};
																				axios
																					.post(
																						`${process.env.REACT_APP_API_URL}/auth/adminthuong`,
																						dataForm
																					)
																					.then((res) => {
																						setLoad(false);
																						swal("Thưởng thành công!");
																					});
																			}}
																		>
																			<input
																				style={{ width: "100px" }}
																				id={item._id}
																				name="money"
																				type="number"
																				step="0.01"
																			/>
																			<Button type="submit">Xác nhận</Button>
																		</form>
																	</TableCell>
																</TableRow>
															))}
													</TableBody>
												) : (
													<div>Đang cập nhật</div>
												)}
											</Table>
										</Box>
									</div>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											pt: 3,
										}}
									>
										{users != null ? (
											<Pagination
												color="primary"
												count={Math.floor(users.length / 30) + 1}
												size="small"
												onChange={handleChange}
											/>
										) : null}
									</Box>
								</Container>
							</Box>
						}
					</DashboardLayout>
				</ThemeProvider>
			</>
		);
	} else {
		return <></>;
	}
}
export default Users;
