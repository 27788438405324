import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import swal from "sweetalert";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import ChooseLanguage from './ChooseLanguage';

function Login() {
	const { t } = useTranslation();
	const [err, setErr] = useState(null);
	const login = localStorage.getItem("user");

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();
	useEffect(() => {
		if (login) {
			navigate("/");
		}
	}, []);
	const onSubmit = async (data) => {
		if (data.username.length < 6) {
			setError("username", {
				type: "minLength",
				message: "Username " + t("yêu cầu ít nhất 6 kí tự"),
			});
		}

		if (data.password.length < 6) {
			setError("password", {
				type: "minLength",
				message: "Password " + t("yêu cầu ít nhất 6 kí tự"),
			});
		}
		if (data.password.length < 6 || data.username.length < 6) {
			return;
		}
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
			.then((res) => {
				localStorage.setItem("user", res.data.data);
				swal({
					title: t("Thông báo"),
					text: t("Đăng nhập thành công"),
					icon: "success",
					buttons: "OK",
				}).then(() => navigate("/"));
			})
			.catch((err) =>
				setErr(
					err.response.data.message
						? t(err.response.data.message)
						: t("Tài khoản hoặc mật khẩu không đúng")
				)
			);
	};
	const [showPassword, setShowPassword] = useState(false);
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	
	return (
		<>
			<div className="login">
				{/* <Link to="/cskh">
					<div style={{ color: '#087c95', position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}>
						<HeadsetMicOutlinedIcon style={{ width: '30px', height: '38px' }} />
					</div>
				</Link> */}
				<div style={{ position: 'absolute', top: '-0.2rem', cursor: 'pointer' }}>
					<ChooseLanguage />
				</div>

				<form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
					{/*<img src={require("../../img/vietlott3.png")} alt="Logo" className="logologin"/>*/}
					<div style={{ display: 'block', height: '1.5rem' }}>
						<div style={{ color: '#087c95', position: 'absolute', right: '0px', top: '0.5rem', cursor: 'pointer' }}>
							<Link to="/cskh">
								<HeadsetMicOutlinedIcon style={{ width: '30px', height: '38px' }} />
							</Link>
						</div>
					</div>
					<div>
						<img src={require("../../img/logo_img1.png")} style={{ maxHeight: '2rem' }} alt="Logo" />
						<p id='txt--welcome'>{t('welcome')}</p>
					</div>
					<div style={{ textAlign: 'center' }}>
						<Divider style={{ borderColor: '#087c95', width: '39%', margin: 'auto' }} />
					</div>
					<h1>{t('login')}</h1>
					<div className="inputs">
						<div className="input">
							<input
								type="text"
								className="ip-lg"
								{...register("username", { required: true })}
								placeholder={t('username')}
							/>
							{errors.username ? <p>{errors.username.message}</p> : null}
						</div>
						<div className="input">
							<input
								type={showPassword ? "text" : "password"}
								className="ip-lg"
								{...register("password", { required: true })}
								placeholder={t('password')}
							/>
							<div onClick={toggleShowPassword}>
								{showPassword ? <Visibility /> : <VisibilityOff />}
							</div>
							{errors.password ? <p>{errors.password.message}</p> : null}
						</div>
					</div>
					{err ? <p>{err}</p> : null}
					<button className="btn-red-big" type="submit">
						{t('login')}
					</button>
					<p className="p-lg">
						{t('no_account')}{" "}
						<Link className="bold" to="/register">
							{t('register')}
						</Link>
					</p>
				</form>
			</div>
		</>
	);
}
export default Login;
