import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';

export default function FadeMenu() {
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                style={{ fontSize: '0.35rem', fontWeight: 'bold', color: '#0d8da6', fontFamily: "Open Sans, sans-serif", textTransform: 'unset' }}
                onClick={handleClick}
            >
                {i18n.language === 'en' ?
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" width={'22px'}
                            opacity={i18n.language === 'en' ? 1 : 0.3}
                            style={{ marginRight: '5px' }}
                            id="flag-icons-gb" viewBox="0 0 640 480">
                            <path fill="#012169" d="M0 0h640v480H0z" />
                            <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z" />
                            <path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z" />
                            <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
                            <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
                        </svg>{t('Tiếng Anh')}
                    </>
                    : (i18n.language === 'vi'
                        ?
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'22px'}
                                opacity={i18n.language === 'vi' ? 1 : 0.3}
                                style={{ marginRight: '5px' }}
                                id="flag-icons-vn" viewBox="0 0 640 480">
                                <defs>
                                    <clipPath id="vn-a">
                                        <path fillOpacity=".7" d="M-85.3 0h682.6v512H-85.3z" />
                                    </clipPath>
                                </defs>
                                <g fill-rule="evenodd" clip-path="url(#vn-a)" transform="translate(80)scale(.9375)">
                                    <path fill="#da251d" d="M-128 0h768v512h-768z" />
                                    <path fill="#ff0" d="M349.6 381 260 314.3l-89 67.3L204 272l-89-67.7 110.1-1 34.2-109.4L294 203l110.1.1-88.5 68.4 33.9 109.6z" />
                                </g>
                            </svg>{t('Tiếng Việt')}
                        </>
                        :
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-cn" viewBox="0 0 640 480" width={'22px'}
                                opacity={i18n.language === 'cn' ? 1 : 0.3}
                                style={{ marginRight: '5px' }}
                            >
                                <defs>
                                    <path id="cn-a" fill="#ff0" d="M-.6.8 0-1 .6.8-1-.3h2z" />
                                </defs>
                                <path fill="#ee1c25" d="M0 0h640v480H0z" />
                                <use href="#cn-a" width="30" height="20" transform="matrix(71.9991 0 0 72 120 120)" />
                                <use href="#cn-a" width="30" height="20" transform="matrix(-12.33562 -20.5871 20.58684 -12.33577 240.3 48)" />
                                <use href="#cn-a" width="30" height="20" transform="matrix(-3.38573 -23.75998 23.75968 -3.38578 288 95.8)" />
                                <use href="#cn-a" width="30" height="20" transform="matrix(6.5991 -23.0749 23.0746 6.59919 288 168)" />
                                <use href="#cn-a" width="30" height="20" transform="matrix(14.9991 -18.73557 18.73533 14.99929 240 216)" />
                            </svg>{t('Tiếng Trung')}
                        </>
                    )}
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={() => changeLanguage('en')}
                    style={{ fontSize: '0.4rem', color: '#0d8da6', fontFamily: "Open Sans, sans-serif" }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width={'28px'}
                        // onClick={() => changeLanguage('en')}
                        opacity={i18n.language === 'en' ? 1 : 0.3}
                        style={{ marginRight: '5px' }}
                        id="flag-icons-gb" viewBox="0 0 640 480">
                        <path fill="#012169" d="M0 0h640v480H0z" />
                        <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z" />
                        <path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z" />
                        <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
                        <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
                    </svg> {t('Tiếng Anh')}
                </MenuItem>
                <MenuItem onClick={() => changeLanguage('vi')}
                    style={{ fontSize: '0.4rem', color: '#0d8da6', fontFamily: "Open Sans, sans-serif" }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width={'28px'}
                        opacity={i18n.language === 'vi' ? 1 : 0.3}
                        style={{ marginRight: '5px' }}
                        id="flag-icons-vn" viewBox="0 0 640 480">
                        <defs>
                            <clipPath id="vn-a">
                                <path fillOpacity=".7" d="M-85.3 0h682.6v512H-85.3z" />
                            </clipPath>
                        </defs>
                        <g fill-rule="evenodd" clip-path="url(#vn-a)" transform="translate(80)scale(.9375)">
                            <path fill="#da251d" d="M-128 0h768v512h-768z" />
                            <path fill="#ff0" d="M349.6 381 260 314.3l-89 67.3L204 272l-89-67.7 110.1-1 34.2-109.4L294 203l110.1.1-88.5 68.4 33.9 109.6z" />
                        </g>
                    </svg> {t('Tiếng Việt')}
                </MenuItem>
                <MenuItem onClick={() => changeLanguage('cn')}
                    style={{ fontSize: '0.4rem', color: '#0d8da6', fontFamily: "Open Sans, sans-serif" }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-cn" viewBox="0 0 640 480" width={'28px'}
                        style={{ marginRight: '5px' }}
                        opacity={i18n.language === 'cn' ? 1 : 0.3}
                    >
                        <defs>
                            <path id="cn-a" fill="#ff0" d="M-.6.8 0-1 .6.8-1-.3h2z" />
                        </defs>
                        <path fill="#ee1c25" d="M0 0h640v480H0z" />
                        <use href="#cn-a" width="30" height="20" transform="matrix(71.9991 0 0 72 120 120)" />
                        <use href="#cn-a" width="30" height="20" transform="matrix(-12.33562 -20.5871 20.58684 -12.33577 240.3 48)" />
                        <use href="#cn-a" width="30" height="20" transform="matrix(-3.38573 -23.75998 23.75968 -3.38578 288 95.8)" />
                        <use href="#cn-a" width="30" height="20" transform="matrix(6.5991 -23.0749 23.0746 6.59919 288 168)" />
                        <use href="#cn-a" width="30" height="20" transform="matrix(14.9991 -18.73557 18.73533 14.99929 240 216)" />
                    </svg> {t('Tiếng Trung')}
                </MenuItem>
            </Menu>
        </div>
    );
}
