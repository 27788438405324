import { useEffect, useState } from "react";
import LiveChat from "react-livechat";
import Footer from "../../components/Footer/Footer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";

function CSKH() {
	const [profile, setProfile] = useState(null);
	const [isShow, setShow] = useState(false);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);

	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		setIsLoading(true);
		const timer = setTimeout(() => {
			setIsLoading(false);
			window.scrollTo(0, document.body.scrollHeight);
		}, 2000);
		return () => {
			clearTimeout(timer);
		}
	}, []);
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
	}, []);

	// unmount
    // useEffect(() => () => window.scrollTo(0, 0), []);

	return (
		<>
			{isLoading ? (
				<div className="loading">
					<div className="loader"></div>
				</div>
			) : null}
			<div className="main">
				{/* <Header profile={profile} /> */}
				{/* <h1 className="title-h1">Chăm Sóc Khách Hàng</h1> */}
				<div style={{height: '0.2rem'}}></div>
				<div style={{ position: "relative", height: "calc(100vh - 2.2rem" }}>
					<iframe
						src={`https://chat.ichatlink.net/widget/standalone.html?eid=bf0d00bf04612aac68375b561beff908&language=vi&ID=${profile?.iduser || 'guest'}`}
						frameborder="0"
						width="100%"
						height="100%"
					></iframe>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default CSKH;
