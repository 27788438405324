import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import ChooseLanguage from './ChooseLanguage';

function Register() {
	const { t } = useTranslation();
	const [err, setErr] = useState(null);
	const login = localStorage.getItem("user");
	const [affiliate, setAffiliate] = useState(null);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	useEffect(() => {
		if (login) {
			navigate("/");
		} else {
			const urlParams = new URLSearchParams(window.location.search);
			const refParam = urlParams.get("ref");
			if (refParam) {
				setAffiliate(refParam);
			}
			if (localStorage.getItem("affiliate")) {
				setAffiliate(localStorage.getItem("affiliate"));
			}
		}
	}, []);
	const onSubmit = (data) => {
		if (data.username.length < 6) {
			setError("username", {
				type: "minLength",
				message: "Username" + t("yêu cầu ít nhất 6 kí tự"),
			});
		}

		if (data.password.length < 6) {
			setError("password", {
				type: "minLength",
				message: "Password" + t("yêu cầu ít nhất 6 kí tự"),
			});
		}
		if (data.password !== data.ippassword) {
			setError("ippassword", {
				type: "minLength",
				message: t("Nhập lại password"),
			});
		}

		if (
			data.password.length < 6 ||
			data.username.length < 6 ||
			data.password !== data.ippassword
		) {
			return;
		}
		if (data.code === "") {
			axios
				.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
					username: data.username,
					password: data.password,
					code: "admin",
					sdt: data.sdt,
				})
				.then((res) => {
					swal({
						title: t("Thông báo"),
						text: t("Đăng ký thành công"),
						icon: "success",
						buttons: "OK",
					}).then(() => navigate("/login"));
				})
				.catch((err) => {
					setErr(t("Tài khoản đã tồn tại"));
				});
		} else if (data.code) {
			axios
				.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
					username: data.username,
					password: data.password,
					code: data.code,
					sdt: data.sdt,
				})
				.then((res) => {
					swal({
						title: t("Thông báo"),
						text: t("Đăng ký thành công"),
						icon: "success",
						buttons: "OK",
					}).then(() => navigate("/login"));
				})
				.catch((err) => {
					setErr(
						err.response.data.message
							? err.response.data.message
							: "Error"
					);
				});
		}
	};
	const [showPassword, setShowPassword] = useState(false);
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const [showPassword1, setShowPassword1] = useState(false);
	const toggleShowPassword1 = () => {
		setShowPassword1(!showPassword1);
	};

	return (
		<>
			<div className="login">
				<div style={{ position: 'absolute', top: '-0.2rem', cursor: 'pointer' }}>
					<ChooseLanguage />
				</div>

				<form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
					<div style={{ display: 'block', height: '1.5rem' }}>
						<div style={{ color: '#087c95', position: 'absolute', right: '0px', top: '0.5rem', cursor: 'pointer' }}>
							<Link to="/cskh">
								<HeadsetMicOutlinedIcon style={{ width: '30px', height: '38px' }} />
							</Link>
						</div>
					</div>
					{/* <img src={require("../../img/vietlott3.png")} alt="Logo" className="logologin"/> */}
					<div>
						<img src={require("../../img/logo_img1.png")} style={{ maxHeight: '2rem' }} alt="Logo" />
						<p id='txt--welcome'>{t('welcome')}</p>
					</div>
					<div style={{ textAlign: 'center' }}>
						<Divider style={{ borderColor: '#087c95', width: '39%', margin: 'auto' }} />
					</div>
					<h1>{t('register')}</h1>
					<div className="inputs">
						<div className="input">
							<input
								type="text"
								{...register("username", { required: true })}
								className="ip-lg"
								placeholder={t('username')}
							/>
							{errors.username ? <p>{errors.username.message}</p> : null}
						</div>
						<div className="input">
							<input
								type={showPassword ? "text" : "password"}
								className="ip-lg"
								{...register("password", { required: true })}
								placeholder={t('password')}
							/>
							<div onClick={toggleShowPassword}>
								{showPassword ? <Visibility /> : <VisibilityOff />}
							</div>
							{errors.password ? <p>{errors.password.message}</p> : null}
						</div>
						<div className="input">
							<input
								type={showPassword1 ? "text" : "password"}
								className="ip-lg"
								{...register("ippassword", { required: true })}
								placeholder={t('confirm_password')}
							/>
							<div onClick={toggleShowPassword1}>
								{showPassword1 ? <Visibility /> : <VisibilityOff />}
							</div>
							{errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
						</div>
						<div className="input" style={{ display: "none" }}>
							<input
								defaultValue={affiliate}
								type="code"
								className="ip-lg"
								{...register("code")}
								placeholder="Nhập mã giới thiệu"
							/>
						</div>
						<div className="input">
							<input
								pattern="(\+84|0)\d{9,10}"
								type="sdt"
								className="ip-lg"
								{...register("sdt", { required: true })}
								placeholder={t('enter_phone')}
							/>
							{err ? <p>{err}</p> : null}
						</div>
					</div>
					<button type="submit" className="btn-lg">
						{t('register')}
					</button>
					<p className="p-lg">
						{t('have_account')}{" "}
						<Link className="a-lg bold" to="/login">
							{t('login')}
						</Link>
					</p>
				</form>
			</div>
		</>
	);
}
export default Register;
