import React from "react";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { useTranslation } from 'react-i18next';

const Header = ({ profile }) => {
	const { t } = useTranslation();
	const [isShow, setShow] = useState(true);
	return (
		<div className="header">
			<div className="header-top">
				<div className="logo">
					<Link to="/">
						<img src={require("../../img/logo_img1.png")} alt="Logo" />
						{/*<span
							style={{
								color: "#fff",
								fontSize: "24px",
								fontWeight: "bold",
								position: "relative",
								top: "-10px",
							}}
						>
							LOTO99
						</span>*/}
					</Link>
				</div>
				<div className="header-right">
					<div style={{ display: "flex", float: "right" }}>
						{profile ? (
							<div style={{ display: "flex", float: "right" }}>
								<span style={{ marginRight: "0.111rem" }}>
									{t("Số dư")}:{" "}
									<b>
										{isShow
											// ? Math.floor(profile.money).toLocaleString()
											? profile.money
											: "*******"} USDT
									</b>
									{/* {isShow ? (
										<VisibilityOff onClick={() => setShow(!isShow)} />
									) : (
										<Visibility onClick={() => setShow(!isShow)} />
									)} */}
								</span>
							</div>
						) : (
							<div className="button-top">
								<Link to="/login" className="btn-login">
									{t('login')}
								</Link>
								<Link to="/register" className="btn-register">
									{t('register')}
								</Link>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
